import { Box, Grid, makeStyles, Typography, useTheme } from "@material-ui/core"
import { graphql } from "gatsby"
import { Button } from "gatsby-theme-material-ui"
import React, { ReactElement } from "react"

import HoverImg from "../../../components/HoverImg"
import { Layout } from "../../../components/Layout"
import { Link } from "../../../components/Link"

const useStyles = makeStyles({
  overlay: {
    left: "50%",
    position: "absolute",
    textAlign: "center",
    top: "50%",
    transform: "translate(-50%, -50%)",
    whiteSpace: "nowrap",
    zIndex: 1,
  },
  trend: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-end",

    "& h2": {
      flexGrow: 1,
    },
  },
})

interface Props {
  data: any
  location: Location
  pageContext: any
}

function IndexPage(props: Props): ReactElement {
  const classes = useStyles(props)
  const theme = useTheme()
  const { data, location, pageContext } = props
  pageContext.title = "Wintertrends 2019/2020"
  const winterTrends = [
    {
      image: data.leather,
      name: `Leder ist in`,
      path: `/blog/wintertrends-2019-2020/leder`,
    },
    {
      image: data.stripedCoat,
      name: `Mäntel mit Streifen`,
      path: `/blog/wintertrends-2019-2020/gestreifte-mäntel`,
    },
    {
      image: data.patchwork,
      name: `Patchwork`,
      path: `/blog/wintertrends-2019-2020/patchwork`,
    },
  ]
  const sylvester = [
    {
      image: data.creamColored,
      name: `Cremefarben`,
      path: `/blog/wintertrends-2019-2020/cremefarben`,
    },
    {
      image: data.pullover,
      name: `Warme Pullover für den Winter`,
      path: `/blog/wintertrends-2019-2020/warme-pullover-winter`,
    },
    {
      image: data.trenchcoat,
      name: `Trenchcoats`,
      path: `/blog/wintertrends-2019-2020/trenchcoats`,
    },
    {
      image: data.quiltedJacket,
      name: `Steppjacken`,
      path: `/blog/wintertrends-2019-2020/steppjacken`,
    },
  ]
  pageContext.title = "Wintertrends 2019/2020"
  return (
    <Layout location={location} pageContext={pageContext}>
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12}>
          <Typography align="center" variant="h1">
            {pageContext.title}
          </Typography>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true} spacing={1}>
            {sylvester.map((trend, index) => (
              <Grid item={true} key={index} xs={12} sm={3}>
                <Link href={trend.path} className={classes.trend}>
                  <Typography align="center" variant="h2">
                    {trend.name}
                  </Typography>
                  <HoverImg
                    alt={trend.name}
                    backgroundColor={theme.palette.grey[200]}
                    fluid={trend.image.childImageSharp.fluid}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Typography align="center" variant="h2">
            Mode-Statements mit Animal-Print
          </Typography>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={false} sm={6}>
              <Link href={`/blog/wintertrends-2019-2020/kroko-optik`}>
                <HoverImg
                  alt="Must-Haves mit Kroko-Optik"
                  fluid={data.crocoLeft.childImageSharp.fluid}
                />
              </Link>
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <Link href={`/blog/wintertrends-2019-2020/kroko-optik`}>
                <Box position="relative">
                  <div className={classes.overlay}>
                    <Typography align="center" variant="h3">
                      Must-Haves mit Kroko-Optik
                    </Typography>
                    <Button variant="text" size="large">
                      Shop now »
                    </Button>
                  </div>
                  <HoverImg
                    alt="Must-Haves mit Kroko-Optik"
                    fluid={data.crocoRight.childImageSharp.fluid}
                  />
                </Box>
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} sm={6}>
              <Link href={`/blog/wintertrends-2019-2020/leoparden-print`}>
                <Typography align="center" variant="h3">
                  Leoparden-Print
                </Typography>
                <HoverImg alt="Leoparden-Print" fluid={data.leopardPrint.childImageSharp.fluid} />
                <Button variant="text" size="large" fullWidth={true}>
                  Shop now »
                </Button>
              </Link>
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <Link href={`/blog/wintertrends-2019-2020/schlangenleder-optik`}>
                <Typography align="center" variant="h3">
                  Schlangenleder-Optik
                </Typography>
                <HoverImg alt="Leoparden-Print" fluid={data.snakeskinOptic.childImageSharp.fluid} />
                <Button variant="text" size="large" fullWidth={true}>
                  Shop now »
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true} spacing={8}>
            {winterTrends.map((trend, index) => (
              <Grid item={true} key={index} xs={12} sm={4}>
                <Link href={trend.path}>
                  <Typography align="center" variant="h2">
                    {trend.name}
                  </Typography>
                  <HoverImg alt={trend.name} fluid={trend.image.childImageSharp.fluid} />
                  <Button variant="text" size="large" fullWidth={true}>
                    Shop now »
                  </Button>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    leather: file(relativePath: { eq: "leather.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 368, maxHeight: 368) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stripedCoat: file(relativePath: { eq: "striped-coat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 368, maxHeight: 368) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patchwork: file(relativePath: { eq: "patchwork.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 368, maxHeight: 368) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    crocoLeft: file(relativePath: { eq: "croco-left.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 337) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    crocoRight: file(relativePath: { eq: "croco-right.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 337) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leopardPrint: file(relativePath: { eq: "leopard-print.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 337) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    snakeskinOptic: file(relativePath: { eq: "snakeskin-optic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 337) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    creamColored: file(relativePath: { eq: "cream-colored.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    quiltedJacket: file(relativePath: { eq: "quilted-jacket.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pullover: file(relativePath: { eq: "pullover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    trenchcoat: file(relativePath: { eq: "trenchcoat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
